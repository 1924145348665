(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("react"), require("react-redux"), require("@mui/styles"), require("redux"), require("@emotion/styled"), require("@emotion/react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "react", "react-redux", "@mui/styles", "redux", "@emotion/styled", "@emotion/react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["PSE"] = factory(require("@mui/material"), require("react"), require("react-redux"), require("@mui/styles"), require("redux"), require("@emotion/styled"), require("@emotion/react"), require("react-dom"));
	else
		root["PSE"] = factory(root["@mui/material"], root["react"], root["react-redux"], root["@mui/styles"], root["redux"], root["@emotion/styled"], root["@emotion/react"], root["react-dom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__mui_material__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_redux__, __WEBPACK_EXTERNAL_MODULE__mui_styles__, __WEBPACK_EXTERNAL_MODULE_redux__, __WEBPACK_EXTERNAL_MODULE__emotion_styled__, __WEBPACK_EXTERNAL_MODULE__emotion_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__) => {
return 